import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    selectedBank:null,
    selectedProduct:null
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setSelectedBank: (state, action) => {
            state.selectedBank = action.payload
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload
        }

	}
})

export const { setCurrentRouteKey ,setSelectedBank,setSelectedProduct} = commonSlice.actions

export default commonSlice.reducer