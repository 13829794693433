import { useSelector, useDispatch } from "react-redux";

import { apiGenerateOtp, apiSignOut, apiVerifyOtp } from "services/AuthService";
import {
  getCustomerDetails,
  onAddCustomerNumber,
  onOtpGenerate,
  onProductClick,
  onSaveCustomerId,
  onSignInSuccess,
  onSignOutSuccess,
  setCustomerDetails,
  setDialog,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { removeDetails } from "views/myRegistration/Verification/store/verificationSlice";
import { setLang, setPhone } from "store/theme/themeSlice";
import { list } from "views/myRegistration/LoanDetails/utils/utils";

function useAuth() {
  const dispatch = useDispatch();


  const navigate = useNavigate();

  const query = useQuery();

  const {
    token,
    signedIn,
    number,
    cta,
    organization_id,
    role_id,
    referenceId,
    product,
   
  } = useSelector((state) => state.auth.session);

  const {
    locale
  } = useSelector((state) => state.theme);

  const signIn = async ({ mobile, page }) => {
    try {

      const { data } = await apiGenerateOtp({
        phone_number: mobile,
        organization_id: organization_id,
        language: locale.toUpperCase()
      });
      if (data) {
        if (role_id === 1 || role_id === 3 || role_id === 5) {
          dispatch(
            onAddCustomerNumber({ number: mobile, referenceId: data?.results })
          );
          if (!page) {
            navigate("/admin/customer/verify-otp");
          }

          return {
            status: "success",
            message: "",
          };
        } else {
          dispatch(
            onOtpGenerate({ number: mobile, referenceId: data?.results })
          );

          if (!page) {
            navigate("/customer/otp-verification");
          }
          return {
            status: "success",
            message: "",
          };
        }
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const verifyOtp = async ({ otp }) => {
    try {
      const { data } = await apiVerifyOtp({
        phone_number: number,
        otp,
        reference_id: referenceId,
        organization_id: organization_id,
        language: locale.toUpperCase(),
        "product_id": product?.loan_product_id,
        "product_name":product?.loan_product_name
      });

      if (data) {
        if (role_id === 1 || role_id === 3 || role_id === 5) {
          const { customer_id } = data.results;
          dispatch(onSaveCustomerId({ customer_id }));
          dispatch(setCustomerDetails({ phone_number: number }));
          navigate("/admin/customer/verify-customer");

          return {
            status: "success",
            message: "",
          };
        } else {
          const {
            token,
            name,
            status,
            customer_id,
            user_id,
            first_time_login,
            language,
            product_id

          } = data.results;
          if (language) {
            dispatch(setLang(language?.toLowerCase()))
          }
         
          if (product_id && product &&  product_id !== product.value) {
            const prod=list.find(one=>one.value===product_id)
            dispatch(onProductClick(prod))
            dispatch(setDialog(true))
            const signInresp = {
              token,
              name,
              status,
              customer_id,
              user_id,
              first_time_login,
              role_id: 2,
              headerName: name,
            }
            return {
              status: "success",
              message: "",
              signInresp
            };
          } else {
            dispatch(
              onSignInSuccess({
                token,
                name,
                status,
                customer_id,
                user_id,
                first_time_login,
                role_id: 2,
                headerName: name,
              })
            );
            return {
              status: "success",
              message: "",
            };
          }


        }
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setPhone(null))
    dispatch(removeDetails());
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  //   const signOut = async () => {
  //     try {
  //       handleSignOut();
  //     } catch (errors) {
  //       handleSignOut();
  //     }
  //   };

  return {
    authenticated: token && signedIn && number && referenceId,
    signIn,
    verifyOtp,
    handleSignOut,
  };
}

export default useAuth;
