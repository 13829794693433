import React from "react";
import PdfIcon from "assets/svg/icons/pdf.svg";
import { VscFile } from "react-icons/vsc";
import { useSelector } from "react-redux";

const BYTE = 1000;
const getKB = (bytes) => Math.round(bytes / BYTE);

const FileIcon = ({ children }) => {
  return <span className="text-4xl">{children}</span>;
};

const FileItem = (props) => {
  const { file, children, isDialog, showImage } = props;
  const { type, name, size } = file;
  const { themeColor, primaryColorLevel } = useSelector((state) => state.theme);

  const renderThumbnail = () => {
    const isImageFile =
      type?.split("/")[1] === "png" || type?.split("/")[1] === "jpeg";

    if (isImageFile && showImage) {
      console.log("running");
      return (
        <img
          className="upload-file-image"
          src={URL.createObjectURL(file)}
          alt={`file preview ${name}`}
        />
      );
    }

    if (type === "application/pdf") {
      return (
        <FileIcon>
          <img src={PdfIcon} />
        </FileIcon>
      );
    }

    return (
      <FileIcon>
        <VscFile />
      </FileIcon>
    );
  };

  return isDialog ? (
    <div className={`bg-${themeColor}-100 p-2 rounded w-full`}>
      <div className="flex flex-auto">
        <div className="upload-file-thumbnail">{renderThumbnail()}</div>
        <div className="upload-file-info overflow-hidden">
          <h6 className="font-inter text-gray-800 font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden">
            {name}
          </h6>
          <span className="font-normal text-gray-800 text-xs font-inter">
            {getKB(size)} kb
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-gray-50 p-4 rounded w-full">
      <div className="flex">
        <div className="upload-file-thumbnail">{renderThumbnail()}</div>
        <div className="upload-file-info">
          <h6 className="font-inter text-gray-800 font-medium text-base text-ellipsis whitespace-nowrap overflow-hidden">
            {name}
          </h6>
          <span className="font-normal text-gray-800 text-sm font-inter">
            {getKB(size)} kb
          </span>
        </div>
      </div>
    </div>
  );
};

export default FileItem;
