import { GOLD_LOAN_AGRI, HOUSING_LOAN_FARMER, KCC_LOAN, TRACTOR_NEW, TRACTOR_USE } from "constants/loan.constant";
import i18n from "locales";
import AgriKccImage from 'assets/svg/icons/agrikcc.png'
import UsedTractorLoanImage from "assets/svg/icons/used-tractor.png"
import LoanAgriImage from "assets/svg/icons/loan-agri.png"
import CommedityFinance from "assets/svg/icons/commodity-finance.png"
import AgriInfra from "assets/svg/icons/agri-infra.png"
import CommercialEquipment from "assets/svg/icons/commercial-equipment.png"
import CommercialVehicle from "assets/svg/icons/commercial-vehicle.png"
import CreditLoanImage from 'assets/svg/icons/car.png'
import TwoWheelerLoanImage from "assets/svg/icons/two-wheeler.png"
import PersonalLoanImage from "assets/svg/icons/personal.png"
import BusinessLoanImage from "assets/svg/icons/business.png"
import MSMELoanImage from 'assets/svg/icons/msme.png'
import LoanPropertyImage from 'assets/svg/icons/loan-property.png'
import DairyImage from 'assets/svg/icons/dairy.png'
import HousingImage from 'assets/svg/icons/housing-commercial.png'
import GoldLoanImage from 'assets/svg/icons/gold.png'







export const loanList = [
  {
    label: i18n.t('customer.loan_details.loan_list.kcc_loan'),
    value: "KCC - Crop Loan",
    img: AgriKccImage
  },
  {
    label: "Agri Business Loan",
    value: "Gold Loan - Agri",
    img: LoanAgriImage

  },
  {
    label: "Dairy Loan",
    value: "Dairy Loan",
    img: DairyImage

  },
  {
    label: "Commodity Finance",
    value: "CV & CE Loan",
    img: CommedityFinance

  },
  {
    label: i18n.t('customer.loan_details.loan_list.tractor_loan_used'),
    value: "Tractor Loan (Used)",
    img: UsedTractorLoanImage

  },
  {
    label: "Agri Infra Loan",
    value: "Agri Infra",
    img: AgriInfra

  },
  {
    label: 'Gold Loan',
    value: "",
    img: GoldLoanImage
  },


  {
    label: "Housing/Property Loan",
    value: "Dairy Loan",
    img: HousingImage

  },

  {
    label: "Commercial Vehicle",
    value: "Gold Loan",
    img: CommercialVehicle

  },

  {
    label: "Commercial Equipment",
    value: "CV & CE Loan",
    img: CommercialEquipment

  },

  {
    label: 'Car Loan',

    // label: i18n.t('customer.loan_details.loan_list.home'),
    value: "Car Loan",
    img: CreditLoanImage

  },
  {
    label: 'Two Wheeler Loan',
    value: "Two Wheeler Loan",
    img: TwoWheelerLoanImage
  },
  {
    label: 'Personal Loan',
    value: 'Personal Loan',
    img: PersonalLoanImage

  },
  {
    label: "Loan Against Property",
    value: "Gold Loan - Agri",
    img: LoanPropertyImage

  },



  {
    label: "Business Loan",
    value: "Business Loan",
    img: BusinessLoanImage

  },

  {
    label: 'MSME CGTMSE Loan',
    value: "MSME/Business Loan",
    img: MSMELoanImage

  },



];

export const incomeTypeList = [
  { label: i18n.t('customer.loan_details.loan_list.salary'), value: "Salary" },
  { label: i18n.t('customer.loan_details.loan_list.business'), value: "Business with ITR" },
  {
    label: i18n.t('customer.loan_details.loan_list.other_documented'),
    value: "Other documented income,pls specify",
  },
  { label: i18n.t('customer.loan_details.loan_list.no_income'), value: "No additional income" },
];

export const incomeList = [
  { label: i18n.t('customer.loan_details.loan_list.salary'), value: "Salary" },
  { label: i18n.t('customer.loan_details.loan_list.business'), value: "Business with ITR" },
  {
    label: i18n.t('customer.loan_details.loan_list.other_documented'),
    value: "Other documented income,pls specify",
  },

];


export const list = [
  { label: 'KCC & Agri', value: "KCC - Crop Loan" },
  { label: 'Gold Loan ', value: "Gold Loan" },
  { label: 'Tractor Loan', value: "Tractor Loan (New)" },
  { label: 'Agri Infra', value: "Agri Infra" },
  { label: 'CV & CE Loan', value: "CV & CE Loan" },
  { label: 'Car Loan', value: "Car & Auto Loan" },
  { label: 'MSME, BL & PL', value: "MSME/Business Loan" },
  { label: 'Housing Loan', value: "Housing Loan - Farmer" },
]


export const loanProdList = [
  16, 15, 13, 11, 10, 9, 7, 8
]



export const landLoanList = [
  1
]


export const label = {
  "en": 'loan_product_name',
  "hi": 'loan_product_name_hi',
  "mt": 'loan_product_name_mr'
}

export const loanRepaymentOptons = [
  { label: "Regular", value: "regular" },
  { label: "Irregular", value: "irregular" }
]

export const equipmentList = [
  { label: "Tractor", value: 'tractor' },
  { label: "Pickup", value: "pickup" },
  { label: "Car", value: 'car' },
  { label: "Construstion Equipment", value: "construction_equipment" },
  { label: "Truck", value: "truck" },
  { label: "Tipper", value: "tipper" },
  { label: "JCB", value: "jcb" },
 
  { label: "Flat", value: "flat" },
  { label: "Mutual Fund", value: "mutual fund" },
  { label: "LIC", value: "lic" },
  { label: "Stocks", value: "stocks" },
  { label: "FD", value: "fd" },

  { label: "NA Plot", value: "na_plot" },
  { label: "No Assets", value: 'no_assets' },

]

export const naPropertyCollateral=[
  {label:"Constructed Residential NA Property",value:"Constructed Residential NA Property"},
  {label:"Constructed Commercial NA Property",value:"Constructed Commercial NA Property"},
  {label:"Open NA Plot",value:"Open NA Plot"},
  {label:"None",value:"None"}

]



export const naPropertySituated=[
  {label:"Urban",value:"Urban"},
  {label:"Nagar Panchayat",value:"Nagar Panchayat"},
  {label:"Gram Panchayat",value:"Gram Panchayat"},
  {label:"Other",value:"Other"}

]


export const loactionList = [
  { label: "Grampanchayat", value: "grampanchayat" },
  { label: "Taluka", value: "taluka" },
  { label: "District", value: "district" },

]

export const landOwnershipList = [
  { label: "Self", value: "self" },
  { label: "Joint", value: "joint" },
  { label: "Mother", value: "mother" },
  { label: "Father", value: "father" },
  { label: "Sister", value: "sister" },
  { label: "Brother", value: "brother" },
  { label: "Uncle", value: "uncle" },
  { label: "Other Blood Relative", value: "others" },
]


export const incomeDependent = {
  loan_purpose: "",
  business_name: "",
  business_years: "",
  turnover: "",
  itr_file: "",
  itr_filling_years: "",
  no_of_units_of_additional_income_source: "",
  availability_of_income_proof: "",
  agri_land: "",
  area: "",
  itr_amount: "",
  irrigated: "",
  crop_name: "",
  land_ownership: "",
  any_loan: "",
  type_of_loan: "",
  amount_of_loan: "",
  bank_name: "",
  loan_repayment: "",
  any_assets: "",
  constructed_house: "",
  location_property: "",
  property_owner_name: "",
  property_collateral: ""
}



export const agriLandDependent = {
  area: "",
  itr_amount: "",
  irrigated: "",
  crop_name: "",
  land_ownership: "",
}


export const anyLoanDependent = {
  type_of_loan: "",
  amount_of_loan: "",
  bank_name: "",
  loan_repayment: "",
}

export const constructedPropertyDependent = {
  location_property: "",
  property_owner_name: "",
  property_collateral: "",
  estimated_property_value: "",
  other_information: "",
}

export const options = [
  { label: "Yes", value: "yes" }, { label: "No", value: 'no' }
]

export const agriAlliedActivitiesList = [
  { label: "Goatery", value: "Goatery" },
  { label: "Poultry", value: "Poultry" },
  { label: "Dairy", value: "Dairy" },
  { label: "Fisheries", value: "Fisheries" },
  { label: "Shop", value: "Shop" },
  { label: "None", value: "None" },




]