import React from 'react'
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi'
import { useSelector } from 'react-redux'

const NavToggle = ({toggled, className}) => {
	const {domainLogo}=useSelector(({theme})=>theme)
	return (
		<div className={className}>
			{toggled ? <HiOutlineMenu className='text-white'  /> : <div className='flex  items-center'><HiOutlineMenu className='mr-4 text-white'/>
			<div
              className="bg-white flex justify-center"
              style={{ width: "100%", borderRadius: 30 }}
            >
              <img src={domainLogo} className='m-1' style={{height:'30px'}} />
            </div>
			</div>}
		</div>
	)
}

export default NavToggle