import ApiService from './ApiService'

export async function apiGenerateOtp (data) {
    return ApiService.fetchData({
        url: '/auth/generateOtp',
        method: 'post',
        data
    })
}

export async function apiVerifyOtp(data){
    return ApiService.fetchData({
        url:'/auth/verifyOtp',
        method: 'post',
        data
    })
}



export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}


export async function apiOrganizationDetails (params) {
    return ApiService.fetchData({
        url: '/organizations/details',
        method: 'GET',
        params
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/user/send-reset-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/user/reset-password',
        method: 'put',
        data
    })
}

