import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import hi from './lang/hi.json'
import mt from './lang/mt.json'
import { themeConfig } from 'configs/theme.config'

const resources = {
    en: {
        translation: en
    },
    hi: {
        translation: hi
    },
    mt: {
        translation: mt
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: themeConfig.locale,
    lng: 'en',
    interpolation: {
        escapeValue: false
    }
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    hi: () => import('dayjs/locale/hi'),
    mt: () => import('dayjs/locale/mt')
}

export default i18n