import ApiService from "./ApiService";

export async function apiKycDetails(data) {
  return ApiService.fetchData({
    url: "/customers/getKYCDetails",
    method: "post",
    data,
  });
}

export async function apiVerifyAadhaarOTP(data) {
  return ApiService.fetchData({
    url: "/customers/verifyAadhaarDetails",
    method: "post",
    data,
  });
}

export async function apiAddLoan(data) {
  return ApiService.fetchData({
    url: "/customers/loans/addLoanDetails",
    method: "post",
    data,
  });
}

export async function apiAddLoanCPO(data) {
  return ApiService.fetchData({
    url: "/cpo/updateLoanDetails",
    method: "post",
    data,
  });
}

export async function uploadDocument(data) {
  return ApiService.fetchData({
    url: "/customers/loans/uploadLandDocument",
    method: "post",
    data,
  });
}

export async function apiCustomerDetails(params) {
  return ApiService.fetchData({
    url: "/customers/getCustomerDetails",
    method: "GET",
    params,
  });
}

export async function apiGetTemplate(params) {
  return ApiService.fetchData({
    url: "/organizations/getTemplate",
    method: "GET",
    params,
  });
}

export async function updateLandDetails(data) {
  return ApiService.fetchData({
    url: "/customers/loans/updateLandDetails",
    method: "POST",
    data,
  });
}

export async function updateLanguage(data) {
  return ApiService.fetchData({
    url: "/settings/language",
    method: "POST",
    data,
  });
}
