import React from 'react';
import { createSlice } from '@reduxjs/toolkit'
import {
    NAV_ITEM_TYPE_TITLE,
} from "constants/navigation.constant";
export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    nav: [ {
          key: "Krishifin",
          path: "",
          title: "",
          translateKey: "",
          icon: "",
          type: NAV_ITEM_TYPE_TITLE,
          authority: [],
          subMenu: [
            {
              key: "my_registration",
              path: "/dashboard",
              component: React.lazy(() => import("views/myRegistration/Verification")),
              authority: [],
            },
          ],
        }
      ]
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState, 
	reducers: {
    
        setUser: (_, action) =>{
          return  action.payload},
        setNav: (state, action) =>  {
            state.nav = action.payload
        },
        userLoggedOut: () => initialState,
	},
})

export const { setUser, setNav } = userSlice.actions

export default userSlice.reducer