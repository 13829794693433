import React, { useRef, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useConfig } from "../ConfigProvider";
import cloneDeep from "lodash/cloneDeep";
import FileItem from "./FileItem";
import Button from "../Buttons";
import CloseButton from "../CloseButton";
import Notification from "../Notification";
import toast from "../toast";
import FileImage from "assets/svg/icons/file.png";
import { useTranslation } from "react-i18next";
import UploadImage from "assets/svg/icons/upload.png";

const filesToArray = (files) => Object.keys(files).map((key) => files[key]);

const Upload = React.forwardRef((props, ref) => {
  const {
    accept,
    beforeUpload,
    isDialog,
    disabled,
    draggable,
    fileList,
    multiple,
    onChange,
    onFileRemove,
    showList,
    tip,
    uploadLimit,
    children,
    className,
    field,
    form,
    custome,
    allowed_type,
    title,
    showImage = false,
    ...rest
  } = props;

  const fileInputField = useRef(null);

  const [files, setFiles] = useState(fileList);

  const [dragOver, setDragOver] = useState(false);

  const { t } = useTranslation();

  const { themeColor, primaryColorLevel } = useConfig();

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  const triggerMessage = (msg) => {
    toast.push(
      <Notification type="danger" duration={2000}>
        {msg || "Upload Failed!"}
      </Notification>,
      {
        placement: "top-center",
      }
    );
  };

  const pushFile = (newFiles, file) => {
    for (let f of newFiles) {
      file.push(f);
    }
    return file;
  };

  const addNewFiles = (newFiles) => {
    let file = cloneDeep(files);
    if (typeof uploadLimit === "number" && uploadLimit !== 0) {
      if (Object.keys(file).length >= uploadLimit) {
        if (uploadLimit === 1) {
          file.shift();
          file = pushFile(newFiles, file);
        }

        return filesToArray({ ...file });
      }
    }
    file = pushFile(newFiles, file);
    return filesToArray({ ...file });
  };

  const onNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    let result = true;

    if (beforeUpload) {
      result = beforeUpload(newFiles, files);

      if (result === false) {
        triggerMessage();
        return;
      }

      if (typeof result === "string" && result.length > 0) {
        triggerMessage(result);
        return;
      }
    }

    if (result) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      onChange?.(updatedFiles, files, setFiles);
    }
  };

  const removeFile = (fileIndex) => {
    const deletedFileList = files.filter((_, index) => index !== fileIndex);
    setFiles(deletedFileList);
    onFileRemove?.(deletedFileList);
  };

  const triggerUpload = (e) => {
    if (!disabled) {
      fileInputField.current?.click();
    }
    e.stopPropagation();
  };

  const renderChildren = () => {
    if (custome && !children && draggable) {
      return (
        <div
          style={{ height: 44 }}
          className="border w-full flex justify-between items-center px-2"
        >
          <p className="text-gray-400 font-medium font-inter">Browse</p>
          <img src={UploadImage} style={{ width: 20, height: 20 }} />
        </div>
      );
    }

    if (!draggable && !children) {
      return (
        <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
          Upload
        </Button>
      );
    }

    if (draggable && !children) {
      return (
        <div>
          <div className="flex justify-center mb-3">
            <img src={FileImage} alt="" />
          </div>
          <div
            className={`text-${themeColor}-${primaryColorLevel} font-inter font-medium text-base text-center mb-3`}
          >
            {title ? title : t("customer.land_details.click_here")}
          </div>
          <div
            className={`text-${themeColor}-${primaryColorLevel} font-inter font-normal text-sm text-center`}
          >
            {allowed_type
              ? allowed_type
              : t("customer.land_details.allowed_types")}
          </div>
        </div>
      );
    }

    return children;
  };

  const handleDragLeave = useCallback(() => {
    if (draggable) {
      setDragOver(false);
    }
  }, [draggable]);

  const handleDragOver = useCallback(() => {
    if (draggable && !disabled) {
      setDragOver(true);
    }
  }, [draggable, disabled]);

  const handleDrop = useCallback(() => {
    if (draggable) {
      setDragOver(false);
    }
  }, [draggable]);

  const draggableProp = {
    onDragLeave: handleDragLeave,
    onDragOver: handleDragOver,
    onDrop: handleDrop,
  };

  const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`;

  const uploadClass = classNames(
    `${
      custome
        ? ""
        : `upload border-${themeColor}-${primaryColorLevel} bg-${themeColor}-50 p-5`
    }`,
    draggable && `upload-draggable`,
    draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
    draggable && disabled && "disabled",
    dragOver && draggableEventFeedbackClass,
    className
  );

  const uploadInputClass = classNames("upload-input", draggable && `draggable`);

  return (
    <>
      {files.length === 0 && (
        <div
          ref={ref}
          className={uploadClass}
          {...(draggable ? draggableProp : { onClick: triggerUpload })}
          {...rest}
        >
          <input
            className={uploadInputClass}
            type="file"
            ref={fileInputField}
            onChange={onNewFileUpload}
            disabled={disabled}
            multiple={multiple}
            accept={accept}
            title=""
            value=""
            {...field}
            {...rest}
          ></input>
          {renderChildren()}
        </div>
      )}
      {tip}
      {showList && (
        <div className="upload-file-list">
          {files.map((file, index) => (
            <FileItem
              file={file}
              key={file.name + index}
              isDialog={isDialog}
              showImage={showImage}
            >
              <CloseButton
                onClick={() => removeFile(index)}
                className="upload-file-remove"
              />
            </FileItem>
          ))}
        </div>
      )}
    </>
  );
});

Upload.propTypes = {
  uploadLimit: PropTypes.number,
  draggable: PropTypes.bool,
  disabled: PropTypes.bool,
  showList: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Upload.defaultProps = {
  draggable: false,
  showList: true,
  disabled: false,
  fileList: [],
};

export default Upload;
