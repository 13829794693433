import { createSlice, current } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

const verificationSlice = createSlice({
  name: "supplierList/state",
  initialState: {
    aadhaarDetails: null,
    panDetails: null,
    requestId: null,
    aadhaarNumber: null,
    voterDetails:null
  },
  reducers: {
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },
    setAadhaarNumber: (state, action) => {
      state.aadhaarNumber = action.payload;
    },
    setAadhaarDetails: (state, action) => {
      state.aadhaarDetails = action.payload;
    },
    setPanDetails: (state, action) => {
      state.panDetails = action.payload;
    },
    setVoterDetails: (state, action) => {
      state.voterDetails = action.payload;
    },
    removeDetails: (state, action) => {
      state.aadhaarDetails = null;
      state.panDetails = null;
      state.requestId = null;
      state.aadhaarNumber = null;
      state.voterDetails=null;
    },
  },
});

export const {
  setRequestId,
  setAadhaarNumber,
  setAadhaarDetails,
  setPanDetails,
  removeDetails,
  setVoterDetails
} = verificationSlice.actions;

export default verificationSlice.reducer;
